import React from 'react';

class ComponentCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
    };
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps.addedComponents !== this.props.addedComponents) {
      this.saveItems();
    }
  }

  saveItems = () => {
    const components = this.props.addedComponents;
    const names = [];
    components.forEach(function (e) {
      names.push(e.name);
    });
    var counterList = names.reduce(function (obj, item) {
      obj[item] = (obj[item] || 0) + 1;
      return obj;
    }, {});

    this.setState({
      components: counterList,
    });
    this.props.saveList(counterList);
  };

  render() {
    let list;
    if (this.state.components) {
      list = this.state.components;
    }

    return (
      <div className='counter-container'>
        <h5>Component Count</h5>
        <div className='component-list'>
          {Object.keys(list).map((item, id) => {
            return (
              <div key={id} className='counter-row'>
                <span
                  style={{
                    width: '80%',
                  }}
                >
                  {item}
                </span>
                <span
                  style={{
                    width: '20%',
                    textAlign: 'center',
                  }}
                >
                  {list[item]}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ComponentCounter;

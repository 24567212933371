import React from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import DeleteComponent from './DeleteComponent.js';
import RotateObject from './RotateObject.js';
import { connect } from 'react-redux';

class GridObjectItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemLocations: [],
      rotation: 0,
      scale: 1,
      active: false,
      xPos: '',
      yPos: '',
      location: '',
      defaultPostion: {
        x: 0,
        y: 0,
      },
      dragging: false,
      width: window.innerWidth,
    };
    this.itemRef = React.createRef();
  }

  componentDidMount(e) {
    this.setState({
      scale: this.props.scale,
      location: this.props.location,
      defaultPosition: {
        x: this.props.xLocation,
        y: this.props.yLocation,
      },
    });
    this.props.saveGrid(this.props.pieceId, this.state.defaultPosition);
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUpdate(nextProps) {
    if (nextProps.scale !== this.props.scale) {
      this.setState({ scale: nextProps.scale });
    }
    if (nextProps.currentlyActive !== this.props.pieceId && this.state.active === true) {
      this.setState({
        active: false,
      });
    }
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  rotateObject = (e) => {
    e.stopPropagation();
    const { rotation } = this.state;
    const newRotation = rotation + 90;
    this.setState({
      rotation: newRotation,
    });
  };

  deleteObject = (id) => {
    this.props.deleteComponent(id);
  };

  onDragStart = (ev, id) => {
    console.log('On drag start');
    ev.preventDefault();
    // let location = this.getItemLocation();

    // this.selectElement(ev, this.props.pieceId);
    this.props.onDragStart(ev, id);
  };

  onDragStop = (ev, data, id) => {
    ev.preventDefault();
    const { dragging } = this.state;
    this.setState({ dragging: false });
    console.log('On drag stop');
    if (dragging) {
      this.selectElement(ev);
      this.setState({
        defaultPosition: {
          x: data.x,
          y: data.y,
        },
      });
      this.props.saveGrid(id, this.state.defaultPosition);
      console.log('Component was dragged');
    } else {
      console.log('Component was not dragged');
      this.selectElement(ev);
    }
  };
  onDragStopMobile = (ev, data, id) => {
    console.log('drag stopped');
    this.setState({
      defaultPosition: {
        x: data.x,
        y: data.y,
      },
    });
    this.props.saveGrid(id, this.state.defaultPosition);
  };

  getItemLocation = () => {
    const gridLoc = ReactDOM.findDOMNode(this.refs['gridItem']).style.transform;

    return gridLoc;
  };

  selectElement = (ev) => {
    ev.preventDefault();
    console.log('Component was selected');
    const id = this.props.pieceId;
    // this.props.saveActiveItem(id);
    // if (this.props.currentlyActive == id) {
    if (this.state.active === true) {
      this.props.saveActiveItem('');
      this.setState({ active: false });
    } else {
      this.props.saveActiveItem(id);

      this.setState({ active: true });
      this.props.saveActiveItem(id);

      // }
    }
  };

  onDrag = () => {
    this.setState({
      dragging: true,
    });
  };

  removeActive = () => {
    if (this.props.currentlyActive !== this.props.pieceId) {
      this.setState({
        active: false,
      });
    }
  };

  setDefaultPostion = () => {};

  render() {
    const itemWidth = (this.props.width / this.props.grid.width) * this.props.gridWidth;
    const itemHeight = (this.props.height / this.props.grid.height) * this.props.gridHeight;

    let containerSize = {
      width: `${itemWidth}px`,
      height: `${itemHeight}px`,
      // transform: this.state.location
    };
    // console.log(String(this.state.location));
    if (this.state.rotation === 90 || this.state.rotation === 270) {
      containerSize = {
        width: `${itemHeight}px`,
        height: `${itemWidth}px`,
      };
    }
    const size = {
      width: `${itemWidth}px`,
      height: `${itemHeight}px`,
      transform: `translate(-50%,-50%) rotate(${this.state.rotation}deg)`,
    };

    const { width } = this.state;
    const isMobile = width <= 1000;

    if (isMobile) {
      return (
        <div>
          <Draggable
            bounds='.grid'
            defaultPosition={{
              x: this.props.xLocation, // - itemWidth,
              y: this.props.yLocation, // - itemHeight
            }}
            grid={[1, 1]}
            scale={1}
            onStart={(ev) => this.onDragStart(ev, this.props.pieceId)}
            onStop={(ev, data) => this.onDragStop(ev, data, this.props.pieceId)}
            onDrag={this.onDrag}
          >
            <div
              ref={'gridItem'}
              className={`grid-item ${this.state.active === true ? 'active' : ''}`}
              style={containerSize}
              // onClick={e => this.selectElement(e)}
            >
              <img className='object-img' style={size} alt='' draggable src={this.props.img} />
            </div>
          </Draggable>
          {this.state.active ? (
            <div className='object-actions'>
              <DeleteComponent
                deleteComponent={() => {
                  this.deleteObject(this.props.pieceId);
                }}
              />
              <RotateObject rotateComponent={(e) => this.rotateObject(e)} />
            </div>
          ) : (
            ''
          )}
        </div>
      );
    } else {
      return (
        <Draggable
          bounds='.grid'
          defaultPosition={{
            x: this.props.xLocation, // - itemWidth,
            y: this.props.yLocation, // - itemHeight
          }}
          grid={[1, 1]}
          scale={1}
          onStart={(ev) => this.onDragStart(ev, this.props.pieceId)}
          onStop={(ev, data) => this.onDragStopMobile(ev, data, this.props.pieceId)}
        >
          <div
            ref={'gridItem'}
            className={`grid-item ${this.state.active === true ? 'active' : ''}`}
            style={containerSize}
            onClick={(e) => this.selectElement(e)}
          >
            <img className='object-img' style={size} alt='' draggable src={this.props.img} />

            {this.state.active ? (
              <div className='object-actions'>
                <DeleteComponent
                  deleteComponent={() => {
                    this.deleteObject(this.props.pieceId);
                  }}
                />
                <RotateObject rotateComponent={(e) => this.rotateObject(e)} />
              </div>
            ) : (
              ''
            )}
          </div>
        </Draggable>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  grid: state.grid,
});

export default connect(mapStateToProps)(GridObjectItem);

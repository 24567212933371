import React from 'react';
import ObjectItem from './ObjectItem.js';
import ParkObjects from './ParkObjects.js';

class ObjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: '',
      newCounter: 0,
    };
  }

  itemIsDragging = (id, name, img, width, length, touch) => {
    console.log('touching');
    this.props.itemIsDragging(id, name, img, width, length, touch);
  };

  itemIsDropped = (evt, id, img, width, length) => {
    console.log('here');
    this.props.itemIsDropped(evt, id, img, width, length);
  };

  render() {
    return (
      <div className='object-list-container'>
        <h4>Object List</h4>
        <div className='object-item-list'>
          {ParkObjects.map((item, index) => {
            const dimensions = `${item.length} ft x ${item.width} ft`;
            const id = `${item.id}-${Date.now()}`;

            return (
              <ObjectItem
                key={index}
                itemId={index}
                name={item.name}
                img={item.img}
                dimensions={dimensions}
                width={item.width}
                length={item.length}
                itemIsDragging={(name, img, width, length, touch) => this.itemIsDragging(id, name, img, width, length, touch)}
                itemIsDropped={(evt, img, width, length) => this.itemIsDropped(evt, id, img, width, length)}
                // onDrag={this.props.onDrag}
                saveItems={() => this.props.saveItems(id, item.length, item.width, item.img)}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default ObjectList;

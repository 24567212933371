import { SET_GRID_SIZE, TOGGLE_MODAL } from '../actions/types';

const initialState = {
  height: 250,
  width: 250,
  modal: {
    active: false,
    type: 'request',
    title: 'Lets Get This Park Started!',
    reside: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_GRID_SIZE:
      console.log(action);
      return {
        ...state,
        height: action.payload.height,
        width: action.payload.width,
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        modal: {
          active: action.payload.active,
          type: action.payload.type,
          title: action.payload.title,
          resize: action.payload.resize,
        },
      };
    default:
      return state;
  }
}

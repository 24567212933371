import React from 'react';

class ObjectItem extends React.Component {
  onDragStart = (ev, id, name, img, width, length, touch) => {
    console.log('onDragStart', id);
    this.props.itemIsDragging(name, img, width, length, touch);
  };

  onDrop = (ev, id, img, width, length) => {
    console.log(`You dropped${id}`);
    this.props.itemIsDropped(ev, img, width, length);
  };

  render() {
    return (
      <div className='object-item'>
        <div className='object-name'>
          <p>{this.props.name}</p>
        </div>
        <div className='object-item-image'>
          <img
            alt=''
            draggable={true}
            src={this.props.img}
            // onClick={this.props.onClick}
            onDragStart={(e) =>
              this.onDragStart(
                e,
                this.props.itemId,
                this.props.name,
                this.props.img,
                this.props.width,
                this.props.length
              )
            }
            onDrop={(e) => {
              console.log('should call onDrop')
              this.onDrop(
                e,
                this.props.itemId,
                this.props.img,
                this.props.width,
                this.props.length
                )
              }
            }
            onTouchStart={(e) =>
              this.onDragStart(
                e,
                this.props.itemId,
                this.props.name,
                this.props.img,
                this.props.width,
                this.props.length,
                true
              )
            }
            onTouchEnd={(e) => {
              console.log('should call onDrop')
              this.onDrop(
                e,
                this.props.itemId,
                this.props.img,
                this.props.width,
                this.props.length
                )
              }
            }
          />
        </div>
        <p>{this.props.dimensions}</p>
      </div>
    );
  }
}

export default ObjectItem;

var ParkObjects = [
  {
    name: '3D Traverse Climbing Wall 20ft',
    img: 'images/3DClimbingWall20.png',
    length: '20',
    width: '13',
    id: '3DClimbingWall20',
  },
  {
    name: '3D Traverse Climbing Wall 40ft',
    img: 'images/3DClimbingWall40.png',
    length: '40',
    width: '13',
    id: '3DClimbingWall40',
  },
  {
    name: 'Airbag With 2 Launch Lanes',
    img: 'images/Airbag2Lanes.png',
    length: '35',
    width: '32',
    id: 'Airbag2Lanes',
  },
  {
    name: 'Airbag With 2 Launch Lanes, Rope Swing, and Step Up Jump',
    img: 'images/Airbag2LanesRope.png',
    length: '35',
    width: '44',
    id: 'Airbag2LanesRope',
  },
  {
    name: 'Airbag With 1 Launch Lane',
    img: 'images/Airbag1Lanes.png',
    length: '35',
    width: '18',
    id: 'Airbag1Lanes',
  },
  {
    name: 'Air Take Off Attraction',
    img: 'images/AirTakeOff.png',
    length: '40',
    width: '40',
    id: 'AirTakeOff',
  },
  {
    name: 'Air Track 33ft',
    img: 'images/AirTrack33.png',
    length: '33',
    width: '7',
    id: 'AirTrack33',
  },
  {
    name: 'Air Track 40ft',
    img: 'images/AirTrack40.png',
    length: '40',
    width: '7',
    id: 'AirTrack40',
  },
  {
    name: 'Double Air Track 33ft',
    img: 'images/DoubleAirTrack33.png',
    length: '33',
    width: '14',
    id: 'DoubleAirTrack33',
  },
  {
    name: 'Air Track Sport Court',
    img: 'images/AirTrackSportCourt.png',
    length: '40',
    width: '20',
    id: 'AirTrackSportCourt',
  },
  {
    name: 'Battle Beam',
    img: 'images/BattleBeam.png',
    length: '16',
    width: '14',
    id: 'BattleBeam',
  },
  {
    name: 'Butterfly Trampoline',
    img: 'images/ButterflyTrampoline.png',
    length: '10',
    width: '32',
    id: 'ButterflyTrampoline',
  },

  {
    name: 'Elevated Jump Box',
    img: 'images/ElevatedJumpBox.png',
    length: '10',
    width: '10',
    id: 'ElevatedJumpBox',
  },
  {
    name: 'Extreme Dodgeball',
    img: 'images/ExtremeDodgeball.png',
    length: '52',
    width: '30',
    id: 'ExtremeDodgeball',
  },
  {
    name: 'Flat Trampoline 10x10',
    img: 'images/FlatTrampoline10x10.png',
    length: '10',
    width: '10',
    id: 'FlatTrampoline10x10',
  },
  {
    name: 'Flat Trampoline 10x20',
    img: 'images/FlatTrampoline10x20.png',
    length: '10',
    width: '20',
    id: 'FlatTrampoline10x20',
  },
  {
    name: 'Flat Trampoline 10x30',
    img: 'images/FlatTrampoline10x30.png',
    length: '10',
    width: '30',
    id: 'FlatTrampoline10x30',
  },
  {
    name: 'Flat Trampoline 10x40',
    img: 'images/FlatTrampoline10x40.png',
    length: '10',
    width: '40',
    id: 'FlatTrampoline10x40',
  },
  {
    name: 'Kiddie Court',
    img: 'images/KiddieCourt.png',
    length: '30',
    width: '20',
    id: 'KiddieCourt',
  },
  {
    name: 'Kiddie Foam Pit',
    img: 'images/KiddieFoamPit.png',
    length: '10',
    width: '15',
    id: 'KiddieFoamPit',
  },
  {
    name: 'Kiddie Dunk Hoop',
    img: 'images/KiddingDunkHoop.png',
    length: '10',
    width: '8',
    id: 'KiddingDunkHoop',
  },
  {
    name: 'Kiddie Inflatable Park 20x20',
    img: 'images/KiddieInflatablePark20.png',
    length: '20',
    width: '20',
    id: 'KiddieInflatablePark20',
  },
  {
    name: 'Kiddie Inflatable Park 30x40',
    img: 'images/KiddieInflatablePark30.png',
    length: '40',
    width: '30',
    id: 'KiddieInflatablePark30',
  },
  {
    name: 'Kiddie Inflatable Park 40x70',
    img: 'images/KiddieInflatablePark40.png',
    length: '70',
    width: '40',
    id: 'KiddieInflatablePark40',
  },
  {
    name: 'Flat Kiddie Court',
    img: 'images/FlatKiddieCourt.png',
    length: '30',
    width: '20',
    id: 'FlatKiddieCourt',
  },
  {
    name: 'Large Ninja Course',
    img: 'images/LargeNinja.png',
    length: '40',
    width: '40',
    id: 'LargeNinja',
  },
  {
    name: 'Medium Ninja Course',
    img: 'images/MediumNinja.png',
    length: '30',
    width: '30',
    id: 'MediumNinja',
  },
  {
    name: 'Small Ninja Course',
    img: 'images/SmallNinja.png',
    length: '30',
    width: '20',
    id: 'SmallNinja',
  },
  {
    name: 'Log Roll',
    img: 'images/LogRoll.png',
    length: '14',
    width: '16',
    id: 'LogRoll',
  },
  {
    name: 'Olympic Trampoline',
    img: 'images/OlympicTrampoline.png',
    length: '10',
    width: '17',
    id: 'OlympicTrampoline',
  },
  {
    name: 'Single Dunk Hoop',
    img: 'images/SingleDunkHoop.png',
    length: '18',
    width: '10',
    id: 'SingleDunkHoop',
  },
  {
    name: 'Double Dunk Hoop',
    img: 'images/DoubleDunkHoop.png',
    length: '18',
    width: '20',
    id: 'DoubleDunkHoop',
  },
  {
    name: 'Triple Dunk Hoop',
    img: 'images/TripleDunkHoop.png',
    length: '18',
    width: '30',
    id: 'TripleDunkHoop',
  },
  {
    name: 'Slack Line',
    img: 'images/SlackLine.png',
    length: '14',
    width: '24',
    id: 'SlackLine',
  },
  {
    name: 'Single Station Tall Wall',
    img: 'images/SingleStationTallWall.png',
    length: '4',
    width: '10',
    id: 'SingleStationTallWall',
  },
  {
    name: 'Double Station Tall Wall',
    img: 'images/DoubleStationTallWall.png',
    length: '8',
    width: '10',
    id: 'DoubleStationTallWall',
  },
  {
    name: 'Spine With Surrounding Trampolines',
    img: 'images/SpineTrampolines.png',
    length: '36',
    width: '30',
    id: 'SpineTrampolines',
  },

  {
    name: 'Standard Dodgeball Court',
    img: 'images/StandardDodgeball.png',
    length: '42',
    width: '40',
    id: 'StandardDodgeball',
  },
  {
    name: 'Super Quad Trampoline',
    img: 'images/SuperQuadTrampoline.png',
    length: '15',
    width: '15',
    id: 'SuperQuadTrampoline',
  },

  {
    name: 'Vertical Wall Trampoline',
    img: 'images/VerticalWallTrampoline.png',
    length: '10',
    width: '24',
    id: 'VerticalWallTrampoline',
  },
];

module.exports = ParkObjects;

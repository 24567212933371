// Auth Actions
export const GET_ERRORS = 'GET_ERRORS';
export const USER_LOADING = 'USER_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_ADMIN = 'SET_CURRENT_ADMIN';

// Grid Actions
export const SET_GRID_SIZE = 'SET_GRID_SIZE';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

// Admin Actions
export const GET_ALL_USERS = 'GET_ALL_USERS';

import { combineReducers } from 'redux';
import authReducer from './authReducers';
import errorReducer from './errorReducers';
import gridReducer from './gridReducers';
import adminReducer from './adminReducers';
export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  grid: gridReducer,
  admin: adminReducer,
});

import React from 'react';

class RotateObject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: '',
    };
  }

  render() {
    return (
      <div className='rotate-component' onClick={this.props.rotateComponent}>
        <img alt='' src='images/Rotate.png' />
      </div>
    );
  }
}

export default RotateObject;

import React from 'react';

class DeleteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: '',
    };
  }

  render() {
    return (
      <div
        className='delete-component'
        onClick={() => {
          this.props.deleteComponent();
        }}
      >
        <img alt='' src='images/TrashCan.png' />
      </div>
    );
  }
}

export default DeleteComponent;
